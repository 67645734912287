import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingSpinner from "../components/LoadingSpinner";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { SpeakerWaveIcon } from "@heroicons/react/24/outline";
import { formatDate } from "../utils";
import SEO from "../components/SEO";
import ReactGA from "react-ga4";

interface Note {
  id: number;
  title: string;
  createdAt: string;
  format: string;
  text: string;
  echoText: string | null;
  isEchoTextEnabled: boolean;
  author: string;
}

interface GetNotesResponse {
  notes: Note[];
  pagination: {
    pageNumber: number;
    pageSize: number;
    hasMore: boolean;
  };
}

const AllNotes = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [notes, setNotes] = useState<GetNotesResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  const fetchNotes = async (pageNumber: number = 0) => {
    setIsError(false);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/notes?pageNumber=${pageNumber}&pageSize=20`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch notes");
      }

      const data = await response.json();

      if (pageNumber > 0 && notes) {
        setNotes({
          notes: [...notes.notes, ...data.notes],
          pagination: data.pagination,
        });
      } else {
        setNotes(data);
      }
    } catch (error) {
      console.error("Error fetching notes:", error);
      setIsError(true);
      setErrorMessage(
        "Failed to fetch notes. Please try again later or contact support@echonotes.ai."
      );
    } finally {
      setIsLoading(false);
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchNotes(0);
  }, [getAccessTokenSilently]);

  const handleLoadMore = () => {
    if (!notes?.pagination || !notes.pagination.hasMore || isLoadingMore)
      return;
    setIsLoadingMore(true);
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    fetchNotes(nextPage);
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center gap-4 py-28">
        <LoadingSpinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex items-center justify-center py-28">
        <div className="bg-red-50 border border-red-200 p-4 w-full max-w-2xl mx-4 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-md text-red-700">{errorMessage}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <SEO
        title="Your Notes | Echo"
        description="View and manage all your voice and text notes"
        isAuthRequired={true}
      />
      <div className="py-20">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          {!notes?.notes || notes.notes.length === 0 ? (
            <div className="text-center max-w-2xl mx-auto my-10">
              <img
                src="/logo-app-store-rating.png"
                alt="App Store Rating"
                className="sm:w-auto max-w-32 inline mb-8"
              />
              <h2 className="text-3xl tracking-tight text-gray-900 sm:text-4xl font-serif sm:leading-[1.2] mb-2">
                Start capturing thoughts
              </h2>
              <p className="text-lg text-gray-600 mb-8">
                Echo mobile lets you quickly capture thoughts on the go, while
                the web app helps you explore and develop them further. Download
                the mobile app to begin.
              </p>
              <a
                href="https://apps.apple.com/us/app/echo-ai-notes/id6503351019"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() =>
                  ReactGA.event({
                    category: "Download",
                    action: "App Store Click",
                    label: "Notes Zero State",
                  })
                }
              >
                <img
                  src="/app-store-button-black.png"
                  alt="Download on the App Store"
                  className="w-32 inline mx-auto"
                />
              </a>
            </div>
          ) : (
            <>
              <h1 className="text-2xl font-bold text-gray-900 mb-6">Notes</h1>
              <div className="bg-white shadow rounded-lg">
                <ul className="divide-y divide-gray-200">
                  {notes.notes.map((note) => (
                    <li key={note.id}>
                      <Link
                        to={`/notes/${note.id}`}
                        className="block hover:bg-gray-50"
                      >
                        <div className="px-4 py-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2">
                              {note.format === "audio" && (
                                <SpeakerWaveIcon className="h-5 w-5 text-gray-400" />
                              )}
                              {note.author === "system" && (
                                <img
                                  className="h-5 w-auto opacity-50"
                                  src="/logo192Black.png"
                                  alt="System Logo"
                                />
                              )}
                              <p className="text-sm font-medium text-gray-900 truncate">
                                {note.title}
                              </p>
                            </div>
                            <div className="ml-2 flex-shrink-0 flex">
                              <p className="text-sm text-gray-500">
                                {formatDate(note.createdAt)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>

                {notes.pagination.hasMore && (
                  <div className="px-4 py-4 sm:px-6 border-t border-gray-200">
                    <button
                      onClick={handleLoadMore}
                      disabled={isLoadingMore}
                      className="w-full text-center text-sm text-gray-500 hover:text-gray-700"
                    >
                      {isLoadingMore ? "Loading..." : "Load more notes"}
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AllNotes;
