import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import {
  ChevronDownIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/20/solid";

type AccountDropdownProps = {
  onLogout: () => void;
};

export default function AccountDropdown({ onLogout }: AccountDropdownProps) {
  return (
    <Menu as="div" className="relative inline-block">
      <div>
        <Menu.Button className="flex items-center text-sm rounded-full text-gray-400 hover:text-gray-600 focus:outline-none">
          <span className="sr-only">Open options</span>
          <span className="pr-2 font-semibold text-gray-700">Account</span>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in">
          <div className="py-1">
            <Menu.Item>
              <Link
                to="/settings"
                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
              >
                Settings
              </Link>
            </Menu.Item>
            <Menu.Item>
              <a
                href="https://chromewebstore.google.com/detail/echo-browser-extension/lechneknbdfkcacecccddecdlekjodgm"
                target="_blank"
                rel="noopener noreferrer"
                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none flex items-center"
              >
                Chrome Extension
                <ArrowTopRightOnSquareIcon
                  className="h-4 w-4 ml-1"
                  aria-hidden="true"
                />
              </a>
            </Menu.Item>
            <Menu.Item>
              <button
                onClick={onLogout}
                className="block w-full px-4 py-2 text-left text-sm text-gray-700"
              >
                Sign out
              </button>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
