import { useEffect, useRef, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useToast } from "../components/Toast";

export function TokenMonitor() {
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } =
    useAuth0();
  const { showToast } = useToast();

  const redirectingRef = useRef(false);
  const checkTokenValidity = useCallback(async () => {
    if (!isAuthenticated) return;

    try {
      // Attempt to get a fresh token
      await getAccessTokenSilently();
    } catch (error: unknown) {
      if (error && typeof error === "object" && "error" in error) {
        if (
          error.error === "login_required" ||
          error.error === "invalid_grant"
        ) {
          // Handle session expiration or invalid refresh token
          showToast("Your session has expired. Please log in again.", "red");
          if (!redirectingRef.current) {
            redirectingRef.current = true; // prevent further redirects
            loginWithRedirect({
              appState: { returnTo: window.location.pathname },
            });
          }
        }
      }
    }
  }, [getAccessTokenSilently, isAuthenticated, loginWithRedirect, showToast]);

  const checkTokenExpiration = useCallback(async () => {
    if (!isAuthenticated) return;

    try {
      const token = await getAccessTokenSilently();
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const expiresIn = decodedToken.exp * 1000 - Date.now();

      // Show warning 5 minutes before expiration
      if (expiresIn < 5 * 60 * 1000 && expiresIn > 0) {
        showToast(
          "Your session will expire soon. Please save your work.",
          "yellow"
        );
      }
    } catch (error) {
      // Token is already invalid, will be handled by checkTokenValidity
    }
  }, [getAccessTokenSilently, isAuthenticated, showToast]);

  useEffect(() => {
    // Check token immediately
    checkTokenValidity();
    checkTokenExpiration();

    // Check token periodically (every minute)
    const intervalId = setInterval(() => {
      checkTokenValidity();
      checkTokenExpiration();
    }, 60 * 1000); // Check every minute

    // Check token when tab becomes visible again
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        checkTokenValidity();
        checkTokenExpiration();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      clearInterval(intervalId);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [checkTokenValidity, checkTokenExpiration]);

  return null;
}

export default TokenMonitor;
