import React, { useState } from "react";
import { TrashIcon, PlusIcon } from "@heroicons/react/24/outline";

interface SuggestedTopicProps {
  topicName: string;
  onAccept: (topicName: string) => void;
  onClear: (topicName: string) => void;
}

const SuggestedTopic: React.FC<SuggestedTopicProps> = ({
  topicName,
  onAccept,
  onClear,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleAccept = async () => {
    setIsProcessing(true);
    await onAccept(topicName);
    setIsProcessing(false);
  };

  const handleClear = async (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent triggering accept when clicking the trash icon
    setIsProcessing(true);
    await onClear(topicName);
    setIsProcessing(false);
  };

  return (
    <div className="inline-flex items-center items-stretch justify-between text-sm font-medium rounded-full bg-white border border-gray-200">
      <div
        className="px-3 py-1 flex items-center rounded-l-full cursor-pointer transition-colors hover:bg-gray-200"
        onClick={handleAccept}
      >
        <PlusIcon className="h-4 w-4 mr-2 custom-stroke stroke-gray-900" />
        <span className="flex-grow text-gray-900">{topicName}</span>
      </div>
      <div
        className="px-3 py-1 flex items-center rounded-r-full border-l border-gray-200 cursor-pointer transition-colors hover:bg-gray-200"
        onClick={handleClear}
      >
        <TrashIcon className="h-4 w-4 text-red-500" />
      </div>
    </div>
  );
};

export default SuggestedTopic;
