import { useAuth0 } from "@auth0/auth0-react";
import { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { formatISO } from "date-fns";
import AccountDropdown from "./AccountDropdown";
import { useToast } from "../components/Toast";
import SpinningCircle from "./SpinningCircle";
import { XMarkIcon, PencilIcon } from "@heroicons/react/24/outline";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const SEARCH_PROMPTS = [
  {
    text: "Find obscure connections in my notes",
  },
  {
    text: "Find direct connections in my notes",
  },
  {
    text: "Find curious connections in my notes",
  },
  {
    text: "Give me a recap of my past week",
  },
  {
    text: "What do I need to follow up on?",
  },
  {
    text: "What is one thing that you can tell me about myself that I may not know about myself?",
  },
  {
    text: "Speculate on things about me that have a high probability, but cannot be confirmed with any confidence",
  },
  {
    text: "What's a recurring theme in my notes that I may not have noticed?",
  },
  {
    text: "What's an idea that I might be excited about that I haven't thought of yet",
  },
  {
    text: "For my upcoming choices, what decisions do you see me making if you read between the lines of my notes?",
  },
  {
    text: "What of my day-to-day choices conflict with my long-term aspirations?",
  },
  {
    text: "What do you see as my coping mechanism for recent challenges?",
  },
  {
    text: "Present to me a worst-case scenario for each of my important challenges",
  },
];

export default function AuthenticatedLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { logout, getAccessTokenSilently } = useAuth0();
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");
  const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);
  const [feedbackError, setFeedbackError] = useState("");
  const location = useLocation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const searchTextAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [isWriteDialogOpen, setIsWriteDialogOpen] = useState(false);
  const [noteText, setNoteText] = useState("");
  const [isSubmittingNote, setIsSubmittingNote] = useState(false);
  const [noteError, setNoteError] = useState("");

  useEffect(() => {
    document.body.classList.add("authenticated-layout");

    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "k") {
        event.preventDefault();
        setSearchQuery("");
        setIsSearchDialogOpen(true);
      }
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        event.preventDefault();
        setNoteText("");
        setIsWriteDialogOpen(true);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.body.classList.remove("authenticated-layout");
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const navigation = [
    {
      name: "Topics",
      href: "/dashboard",
      current: location.pathname === "/dashboard",
    },
    {
      name: "Notes",
      href: "/notes",
      current: location.pathname === "/notes",
    },
  ];

  const handleLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleSubmitFeedback = async () => {
    setIsSubmittingFeedback(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/feedback`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ text: feedbackText }),
        }
      );
      if (!response.ok) throw new Error("Failed to submit feedback");
      setFeedbackText("");
      setIsFeedbackDialogOpen(false);
      showToast("Feedback submitted successfully!", "green");
    } catch (error) {
      console.error("Failed to send feedback:", error);
      setFeedbackError(
        "Failed to submit feedback. Email support@echonotes.ai."
      );
    } finally {
      setIsSubmittingFeedback(false);
    }
  };

  const handleSubmitNote = async () => {
    setIsSubmittingNote(true);
    try {
      const token = await getAccessTokenSilently();
      // Track analytics
      window.analytics?.track("Recording | Save | Started", {
        isSync: true,
        origin: "NavBar",
        format: "written",
        transcriptLength: noteText.length,
      });

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/notes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            text: noteText,
            format: "written",
            currentTimestamp: formatISO(new Date()),
            idempotencyKey: crypto.randomUUID(),
          }),
        }
      );

      if (!response.ok) throw new Error("Failed to create note");
      const data = await response.json();

      setNoteText("");
      setIsWriteDialogOpen(false);
      showToast("Note created successfully!", "green");

      // Track success analytics
      window.analytics?.track("Recording | Save | Succeeded", {
        isSync: true,
        origin: "NavBar",
        format: "written",
        transcriptLength: noteText.length,
      });

      // Navigate to the new note using the noteId from the response
      if (data.noteId) {
        navigate(`/notes/${data.noteId}`);
      }
    } catch (error) {
      console.error("Failed to create note:", error);
      setNoteError("Failed to create note. Please try again.");

      // Track failure analytics
      window.analytics?.track("Recording | Save | Failed", {
        isSync: true,
        origin: "NavBar",
        format: "written",
        transcriptLength: noteText.length,
      });
    } finally {
      setIsSubmittingNote(false);
    }
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      setIsSearchDialogOpen(false);
      window.analytics?.track("Ask | Submit | Started", {
        question: searchQuery,
      });
      navigate(`/askecho?q=${encodeURIComponent(searchQuery.trim())}`);
      setSearchQuery("");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <nav className="mx-0 sm:mx-2 my-4">
        <div className="mx-auto flex flex-col-reverse sm:flex-row justify-between items-center max-w-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center w-full sm:w-auto mt-4 sm:mt-0 mb-2 sm:mb-0">
            <nav aria-label="Tabs" className="flex space-x-4">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-200 text-gray-700"
                      : "text-gray-500 hover:text-gray-700",
                    "rounded-md px-3 py-2 text-sm font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Link>
              ))}
            </nav>
          </div>
          <div className="flex flex-grow items-center sm:max-w-2xl px-4 sm:px-8">
            <div className="hidden lg:block flex-grow">
              <button
                onClick={() => {
                  setSearchQuery("");
                  setIsSearchDialogOpen(true);
                }}
                className="w-full text-left"
              >
                <div className="relative">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
                    <img
                      src="/logo192Black.png"
                      className="h-7 w-auto text-gray-400 z-10"
                      alt="Company Logo"
                      aria-hidden="true"
                    />
                  </div>
                  {/* Large view input */}
                  <input
                    type="text"
                    placeholder="Ask Echo anything..."
                    className="w-full shadow-sm drop-shadow-sm rounded-[1.75rem] border-0 py-3.5 pl-14 pr-4 text-gray-900 placeholder:text-gray-400 placeholder:text-sm sm:text-m sm:leading-6"
                    readOnly
                  />
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-5">
                    <span className="text-sm text-gray-400">
                      {navigator.platform.toLowerCase().includes("mac")
                        ? "⌘"
                        : "ctrl"}{" "}
                      K
                    </span>
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div className="flex items-center justify-between sm:justify-start py-4 sm:py-0 w-full sm:w-auto">
            <button
              onClick={() => setIsFeedbackDialogOpen(true)}
              className="hidden md:block rounded bg-white px-2 py-1 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-6"
            >
              Share feedback
            </button>
            <div className="mr-6">
              <button
                type="button"
                onClick={() => {
                  setNoteText("");
                  setIsWriteDialogOpen(true);
                }}
                className="rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 flex items-center gap-2"
              >
                <PencilIcon className="h-4 w-4" />
                Write
                <span className="text-gray-400 ml-1 font-normal">
                  {navigator.platform.toLowerCase().includes("mac")
                    ? "⌘ ⏎"
                    : "Ctrl + ⏎"}
                </span>
              </button>
            </div>
            <AccountDropdown onLogout={handleLogout} />
          </div>
        </div>
        <div className="block lg:hidden w-full px-4 sm:px-6 my-4">
          <button
            onClick={() => {
              setSearchQuery("");
              setIsSearchDialogOpen(true);
            }}
            className="w-full text-left"
          >
            <div className="relative">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
                <img
                  src="/logo192Black.png"
                  className="h-7 w-auto text-gray-400 z-10"
                  alt="Company Logo"
                  aria-hidden="true"
                />
              </div>
              {/* Small view input */}
              <input
                type="text"
                placeholder="Ask Echo anything..."
                className="w-full shadow-sm drop-shadow-sm rounded-[1.75rem] border-0 py-3.5 pl-14 pr-4 text-gray-900 placeholder:text-gray-400 placeholder:text-sm sm:text-m sm:leading-6"
                readOnly
              />
            </div>
          </button>
        </div>
      </nav>

      {/* Search Dialog */}
      <Transition.Root show={isSearchDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={setIsSearchDialogOpen}
          initialFocus={searchTextAreaRef}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto flex justify-center p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl flex flex-col max-h-[80vh]">
                <div className="flex-shrink-0 px-6 sm:px-8 py-6 sm:py-8 border-b">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900 flex justify-between items-center"
                  >
                    Ask Echo anything
                    <button
                      onClick={() => setIsSearchDialogOpen(false)}
                      className="text-gray-400 hover:text-gray-600 focus:outline-none"
                    >
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </Dialog.Title>
                  <div className="mt-2">
                    <form onSubmit={handleSearch}>
                      <textarea
                        ref={searchTextAreaRef}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Type to ask Echo..."
                        autoFocus
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            handleSearch(e);
                          }
                        }}
                      />
                      <div className="mt-3 flex md:flex-row flex-col justify-between">
                        <p className="text-sm/6 text-gray-600 sm:pb-0 pb-3">
                          Echo uses your notes to answer your questions.
                        </p>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="flex-1 overflow-y-auto px-6 sm:px-8 py-6 sm:py-8">
                  <h4 className="text-sm font-medium text-gray-900 mb-3">
                    Try asking
                  </h4>
                  <div className="space-y-2">
                    {SEARCH_PROMPTS.map((prompt, index) => (
                      <button
                        key={prompt.text}
                        onClick={() => {
                          window.analytics?.track(
                            "Ask | Predefined Question Selected",
                            {
                              question: prompt.text,
                            }
                          );
                          setSearchQuery(prompt.text);
                          setIsSearchDialogOpen(false);
                          navigate(
                            `/askecho?q=${encodeURIComponent(prompt.text)}`
                          );
                        }}
                        className="w-full text-left group rounded-lg border border-gray-200 p-3 hover:border-indigo-200 hover:bg-indigo-50 transition-all"
                      >
                        <div className="flex justify-between items-center">
                          <div className="text-sm text-gray-900 group-hover:text-indigo-600">
                            {prompt.text}
                          </div>
                          {index < 3 && (
                            <span className="inline-flex items-center gap-x-0.5 rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                              New
                            </span>
                          )}
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <main>{children}</main>

      {/* Feedback Dialog */}
      <Transition.Root show={isFeedbackDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={setIsFeedbackDialogOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Share feedback
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 mb-4">
                        Send feedback directly to the Echo cofounders who will
                        respond to you within 24 hours. Your feedback is
                        confidential.
                      </p>
                      <textarea
                        rows={4}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={feedbackText}
                        onChange={(e) => setFeedbackText(e.target.value)}
                        placeholder="Your feedback..."
                      />
                    </div>
                  </div>
                </div>

                {feedbackError && (
                  <div className="mt-2 text-sm text-red-600">
                    {feedbackError}
                  </div>
                )}

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:bg-gray-300"
                    onClick={handleSubmitFeedback}
                    disabled={isSubmittingFeedback || !feedbackText.trim()}
                  >
                    {isSubmittingFeedback ? (
                      <SpinningCircle size="sm" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setIsFeedbackDialogOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Write Note Dialog */}
      <Transition.Root show={isWriteDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={setIsWriteDialogOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Write a note
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 mb-4">
                        Write your note using markdown formatting.
                      </p>
                      <textarea
                        rows={8}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={noteText}
                        onChange={(e) => setNoteText(e.target.value)}
                        placeholder="Start writing..."
                      />
                    </div>
                  </div>
                </div>

                {noteError && (
                  <div className="mt-2 text-sm text-red-600">{noteError}</div>
                )}

                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:bg-gray-300"
                    onClick={handleSubmitNote}
                    disabled={isSubmittingNote || !noteText.trim()}
                  >
                    {isSubmittingNote ? <SpinningCircle size="sm" /> : "Create"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => setIsWriteDialogOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
