import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useToast } from "../components/Toast";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import SEO from "../components/SEO";

interface FetchEmailEnrollmentResponse {
  isEnrolled: boolean;
}

const SettingsPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { showToast } = useToast();
  const [emailEnrolled, setEmailEnrolled] = useState<boolean | null>(null);
  const [showDeleteAccountAlert, setShowDeleteAccountAlert] = useState(false);
  const [showAccountDeletionConfirmation, setShowAccountDeletionConfirmation] =
    useState(false);
  const [confirmationPhrase, setConfirmationPhrase] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const comparisonPhrase = "I understand this cannot be reversed";
  const { logout } = useAuth0();

  useEffect(() => {
    hydrateEmailEnrollment();
  }, []);

  const hydrateEmailEnrollment = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/user/email-enrollment-status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch email enrollment status");
      }

      const data: FetchEmailEnrollmentResponse = await response.json();
      setEmailEnrolled(data.isEnrolled);
    } catch (error) {
      showToast("Error fetching email preferences", "red");
    }
  };

  const updateEmailPreference = async (isEnrolled: boolean) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/user/email-enrollment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ isEnrolled }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update email preferences");
      }

      window.analytics?.track(
        "Settings | Communications | Email Enrollment Changed",
        {
          isEnrolled: isEnrolled,
        }
      );

      showToast("Email preferences updated", "green");
    } catch (error) {
      showToast("Error updating email preferences", "red");
      // Revert the toggle
      setEmailEnrolled(!isEnrolled);
    }
  };

  const handleDeleteAccount = async () => {
    if (confirmationPhrase !== comparisonPhrase) return;

    setIsDeleting(true);
    window.analytics?.track("Users | User Deletion Started");

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v2/user`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        window.analytics?.track("Users | User Deletion Failed", {
          statusCode: response.status,
        });
        throw new Error("Failed to delete account");
      }

      window.analytics?.track("Users | User Deletion Succeeded");

      showToast("Successfully deleted user", "green");
      setShowAccountDeletionConfirmation(false);
      // Redirect to home or trigger logout
      logout({ logoutParams: { returnTo: window.location.origin } });
    } catch (error) {
      showToast("Error deleting account", "red");
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <SEO
        title="Settings | Echo"
        description="Manage your Echo account settings"
        isAuthRequired={true}
      />
      <div className="py-20">
        <div className="mx-auto max-w-2xl px-4">
          <h1 className="text-2xl font-semibold text-gray-900">Settings</h1>

          {/* Communications Section */}
          <div className="mt-8">
            <h2 className="text-sm font-medium text-gray-500">
              Communications
            </h2>
            <div className="mt-4 space-y-4">
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <span className="text-sm font-medium text-gray-900">
                    Receive Marketing Emails
                  </span>
                </div>
                <button
                  type="button"
                  className={`${
                    emailEnrolled === null
                      ? "bg-gray-300 cursor-not-allowed"
                      : emailEnrolled
                      ? "bg-indigo-600"
                      : "bg-gray-200"
                  } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2`}
                  role="switch"
                  aria-checked={emailEnrolled ?? false}
                  onClick={() => {
                    if (emailEnrolled !== null) {
                      const newValue = !emailEnrolled;
                      setEmailEnrolled(newValue);
                      updateEmailPreference(newValue);
                    }
                  }}
                  disabled={emailEnrolled === null}
                >
                  <span
                    aria-hidden="true"
                    className={`${
                      emailEnrolled ? "translate-x-5" : "translate-x-0"
                    } pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                  />
                </button>
              </div>
            </div>
          </div>

          {/* Account Section */}
          <div className="mt-8">
            <h2 className="text-sm font-medium text-gray-500">Account</h2>
            <div className="mt-4">
              <button
                onClick={() => setShowDeleteAccountAlert(true)}
                className="text-sm font-medium text-red-600 hover:text-red-500"
              >
                Delete Account
              </button>
            </div>
          </div>
        </div>

        {/* Delete Account Alert */}
        <Transition.Root show={showDeleteAccountAlert} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={() => setShowDeleteAccountAlert(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Delete Account
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete your account? This
                          action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => {
                        setShowDeleteAccountAlert(false);
                        setShowAccountDeletionConfirmation(true);
                      }}
                    >
                      Continue
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setShowDeleteAccountAlert(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Account Deletion Confirmation */}
        <Transition.Root show={showAccountDeletionConfirmation} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50"
            onClose={setShowAccountDeletionConfirmation}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Type "{comparisonPhrase}"
                      </Dialog.Title>
                      <div className="mt-4">
                        <input
                          type="text"
                          value={confirmationPhrase}
                          onChange={(e) =>
                            setConfirmationPhrase(e.target.value)
                          }
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder={comparisonPhrase}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 disabled:bg-gray-300 sm:col-start-2"
                      disabled={
                        confirmationPhrase !== comparisonPhrase || isDeleting
                      }
                      onClick={handleDeleteAccount}
                    >
                      {isDeleting ? "Deleting..." : "Delete"}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setShowAccountDeletionConfirmation(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
};

export default SettingsPage;
